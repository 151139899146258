import React from 'react'

import Section from '@components/Section'
import SEO from '@components/SEO'
import Layout from '@components/Layout'
import { Text, Image, Box, Flex } from '@theme-ui/components'

const AboutPage = ({ location, pageContext }): void => {
  return (
    <Layout>
      <SEO pathname={location.pathname} />
      <Section>
        <Box sx={{ margin: '5rem 0 1rem 0', textAlign: 'center' }}>
          <Text sx={{ color: 'primary' }}>Art of Wayfare by Ira & Laru</Text>
        </Box>
        <Flex sx={{ paddingBottom: '5rem' }}>
          <Text sx={{ color: 'primary' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Consectetur adipiscing elit duis tristique sollicitudin nibh. In
            nibh mauris cursus mattis molestie a. Diam in arcu cursus euismod
            quis viverra. Facilisi etiam dignissim diam quis enim lobortis.
            Platea dictumst quisque sagittis purus sit amet volutpat consequat.
            Lectus mauris ultrices eros in cursus turpis massa tincidunt dui.
            Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare
            massa. Vitae sapien pellentesque habitant morbi tristique senectus.
            Nullam eget felis eget nunc. At risus viverra adipiscing at in
            tellus integer feugiat. Id ornare arcu odio ut sem nulla pharetra
            diam sit. Neque sodales ut etiam sit amet nisl. Eget arcu dictum
            varius duis at consectetur lorem donec. Purus gravida quis blandit
            turpis cursus. Curabitur vitae nunc sed velit dignissim sodales ut
            eu. Et ultrices neque ornare aenean euismod. Lectus arcu bibendum at
            varius vel pharetra vel turpis nunc.
          </Text>
        </Flex>

        <Box>
          <Flex
            sx={{
              gap: '1rem',
              alignItems: 'center',
              flexWrap: ['wrap', 'wrap', 'wrap', 'wrap', 'wrap', 'nowrap'],
              color: 'primary'
            }}
          >
            <Image
              sx={{
                borderRadius: '8px',
                width: ['100%', '100%', '100%', '100%', '100%', '100%', '50%']
              }}
              src='https://picsum.photos/600'
            />
            <Box
              sx={{
                padding: '1rem',
                width: ['100%', '100%', '100%', '100%', '100%', '100%', '50%']
              }}
            >
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Consectetur adipiscing elit duis tristique sollicitudin nibh. In
                nibh mauris cursus mattis molestie a. Diam in arcu cursus
                euismod quis viverra. Facilisi etiam dignissim diam quis enim
                lobortis. Platea dictumst quisque sagittis purus sit amet
                volutpat consequat. Lectus mauris ultrices eros in cursus turpis
                massa tincidunt dui. Mattis ullamcorper velit sed ullamcorper
                morbi tincidunt ornare massa. Vitae sapien pellentesque habitant
                morbi tristique senectus. Nullam eget felis eget nunc
              </Text>
            </Box>
          </Flex>

          <Box
            sx={{
              padding: '1rem'
            }}
          />
          <Flex
            sx={{
              gap: '1rem',
              alignItems: 'center',
              flexWrap: [
                'wrap-reverse',
                'wrap-reverse',
                'wrap-reverse',
                'wrap-reverse',
                'wrap-reverse',
                'nowrap'
              ]
            }}
          >
            <Box
              sx={{
                padding: '1rem',
                width: ['100%', '100%', '100%', '100%', '100%', '100%', '50%'],
                color: 'primary'
              }}
            >
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Consectetur adipiscing elit duis tristique sollicitudin nibh. In
                nibh mauris cursus mattis molestie a. Diam in arcu cursus
                euismod quis viverra. Facilisi etiam dignissim diam quis enim
                lobortis. Platea dictumst quisque sagittis purus sit amet
                volutpat consequat. Lectus mauris ultrices eros in cursus turpis
                massa tincidunt dui. Mattis ullamcorper velit sed ullamcorper
                morbi tincidunt ornare massa. Vitae sapien pellentesque habitant
                morbi tristique senectus. Nullam eget felis eget nunc
              </Text>
            </Box>
            <Image
              sx={{
                borderRadius: '8px',
                width: ['100%', '100%', '100%', '100%', '100%', '100%', '50%']
              }}
              src='https://picsum.photos/600'
            />
          </Flex>
        </Box>
      </Section>
    </Layout>
  )
}

export default AboutPage
